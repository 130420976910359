import { cn } from '@/app/_primitives/utils/cn'
import Image from 'next/image'

export function ProfilePair(props: {
  images: Array<string | undefined | null>
  fallback: string
}) {
  return (
    <div className="flex shrink-0 flex-row items-center">
      {props.images.map((image, index) => (
        <Image
          key={index}
          alt=""
          width={37}
          height={37}
          className={cn(
            'rounded-full object-cover border border-[#ffffff] bg-white',
            {
              '-ml-16': index > 0,
            },
          )}
          src={image || props.fallback}
        />
      ))}
    </div>
  )
}
