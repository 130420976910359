// import Close from '@/app/_components/Icon/Close'
// import FileAdd from '@/app/_components/Icon/FileAdd'
import Send from '@/app/_components/Icon/Send'
import { useTempUpload } from '@/app/_hooks/useTempUpload'
import { useMessengerState } from '@/app/_messenger/hooks/useMessengerState'
import { message, useInsertMessage } from '@/app/_messenger/hooks/useWebSocket'
import { useSession } from '@/auth/session.hook'
import { zodResolver } from '@hookform/resolvers/zod'
import { nanoid } from 'nanoid'
import { useCallback } from 'react'
import { z } from 'zod'
// import { useDropzone } from 'react-dropzone'
import { useTypingStatus } from '@/app/_messenger/hooks/useTypingStatus'
import { TextField } from '@/app/_primitives/TextField'
import { useForm } from 'react-hook-form'

export type Form = {
  content: string
  files: File[]
}

export const MsgsFormSchema = z.object({
  content: z.string().refine((value) => {
    const phoneRegex =
      /\b((02|010|011|016|017|018|019|032|042|051|052|053|062|064|031|033|041|043|054|055|061|063)[-_.&\s]?\d{3,4}[-_.&\s]?\d{4}|\d{4}[-_.&\s]?\d{4})\b/g
    const emailRegex = /\b([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})\b/g
    return !emailRegex.test(value) && !phoneRegex.test(value)
  }),
})

export function SendMessageForm({ disabled = false }: { disabled?: boolean }) {
  const [{ selectedConversationId: conversationId, isWssReady }] =
    useMessengerState()
  // isWssReady }] = useMessengerState()
  const { formState, register, handleSubmit, reset } = useForm<Form>({
    // setValue, watch } = useForm<Form>({
    resolver: zodResolver(MsgsFormSchema),
    defaultValues: {
      content: '',
      files: [],
    },
    mode: 'onChange',
  })
  const upload = useTempUpload()
  const keyDownHandler = useTypingStatus(conversationId)
  // const onDrop = useCallback(
  //   async (files: File[]) => {
  //     setValue('files', files)
  //   },
  //   [setValue],
  // )
  // const files = watch('files')
  // const handleDelete = useCallback(
  //   (index: number) => {
  //     setValue(
  //       'files',
  //       files.filter((_, i) => i !== index),
  //     )
  //   },
  //   [files, setValue],
  // )
  // const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const session = useSession()

  const insertMessage = useInsertMessage()

  const handleSubmitSuccess = useCallback(
    async (data: Form) => {
      if (session.status === 'unauthenticated') return
      reset()

      if (data.content) {
        const msg = {
          id: nanoid(),
          conversationId: conversationId!,
          userId: session.data!.user.id,

          type: 'text' as const,
          content: data.content,
          senderType: session.data!.user.type,
          isRead: false,
        }

        insertMessage(msg)
        message(msg)
      }

      if (data.files?.length > 0) {
        const promises = data.files.map(async (file) => {
          const fileName = file.name
          const fileType = file.type
          const fileSize = file.size
          const url = await upload.mutateAsync({ file })

          const msg = {
            id: nanoid(),
            conversationId: conversationId!,
            userId: session.data!.user.id,

            type: 'file' as const,
            content: url,
            senderType: session.data!.user.type,

            fileName,
            fileType,
            fileSize,
            isRead: false,
          }

          insertMessage(msg)
          message(msg)
        })

        await Promise.all(promises)
      }
    },
    [reset, conversationId, upload, session, insertMessage],
  )

  const showWssNotReadyError = disabled === false && isWssReady === false
  const showContentError = disabled === false && formState.errors.content

  return (
    <form
      className="relative flex size-full flex-col items-center gap-12"
      onSubmit={handleSubmit(handleSubmitSuccess)}>
      <div className="flex flex-col gap-4">
        {showContentError && (
          <span className={'text-center text-12 leading-19 text-cherry'}>
            작업과 무관한 개인정보 공유는 금지되며, 정보 유출에 유의해 주세요.
          </span>
        )}
        {showWssNotReadyError && (
          <span className={'c-b4 text-cherry'}>서버에 연결할 수 없습니다.</span>
        )}
        {/* {files.map((file, index) => (
          <div className="flex w-full justify-between" key={index}>
            <span className="c-b4 line-clamp-1">{file.name}</span>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                handleDelete(index)
              }}>
              <Close fill={'#000'} />
            </button>
          </div>
        ))} */}
      </div>
      <div className="relative size-full">
        <TextField
          variant="dark"
          error={Boolean(formState.errors.content)}
          className="w-full rounded-16 pl-24 pr-76"
          placeholder="메시지를 입력해 주세요."
          onKeyDown={keyDownHandler}
          disabled={disabled}
          {...register('content')}
        />
        {/* <input
          className="h-48 w-full rounded-16 border border-[#D9DADB] pl-24 pr-76"
          placeholder="메시지를 입력해 주세요."
          {...register('content')}
        /> */}
        <div className="absolute right-20 top-0 flex h-full items-center gap-8">
          {/* <div className="cursor-pointer" {...getRootProps()}>
            <input className="" {...getInputProps()} />
            <FileAdd />
          </div> */}

          <button
            type={'submit'}
            className="disabled:cursor-not-allowed"
            disabled={Boolean(formState.errors.content) || disabled}>
            <Send />
          </button>
        </div>
      </div>
    </form>
  )
}
