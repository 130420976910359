import { getUserInfo, notifyUnread } from '@/app/_messenger/actions'
import { ChatMessageBubble } from '@/app/_messenger/common/ChatMessageBubble'
import { ConversationCharacter } from '@/app/_messenger/common/ConversationCharacter'
import { Button } from '@/app/_primitives/Button'
import { Dialog, DialogContent, DialogTrigger } from '@/app/_primitives/Dialog'
import { cn } from '@/app/_primitives/utils/cn'
import { useSession } from '@/auth/session.hook'
import { Message } from '@/backend/models/Message'
import { getUserAvatarURL } from '@/libraries/avatar'
import { Close } from '@radix-ui/react-dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { X } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { ReactNode, useMemo, useState } from 'react'
import { useConversation } from '../hooks/useConversations'

function Warning() {
  return (
    <div
      className={
        'flex flex-row items-start gap-4 rounded-10 bg-[#FFF2EF] p-16'
      }>
      <p className={'c-b6 text-11 text-[#F1422D]'}>
        ※ 주의. 이너부스가 제공하는 시스템을 통한 대화나 거래가 아닌, 회원 간의
        직접 거래를 실행하는 경우, 분쟁 발생 시 신속한 조치 및 중재가
        불가합니다.
      </p>
    </div>
  )
}

function NotifyUnread(props: { userId: string; conversationId: string }) {
  const [open, setOpen] = useState(false)
  const query = useQuery({
    queryKey: ['user-info', props.userId],
    queryFn: () => getUserInfo(props.userId),
  })
  const notify = useMutation({
    mutationFn: async () => {
      await notifyUnread(props.conversationId, props.userId)
    },
    onSuccess: () => {
      setOpen(false)
    },
  })
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <span>안 읽음</span>
        <span className="text-[#3366b6] underline">리마인드 메일보내기</span>
      </DialogTrigger>
      <DialogContent className="flex !w-433 flex-col items-stretch !rounded-20 border-none !p-20">
        <div className="mb-8 flex flex-row items-start justify-between">
          <p className="c-b2 text-[#1a1a1a]">리마인드 메일을 보내시겠습니까?</p>
          <Close asChild className="cursor-pointer">
            <X />
          </Close>
        </div>
        <p className="c-b4 mb-12 text-[#8b8b8b]">
          {query.data?.name || '...'}님에게 채팅알림 메일이 전송됩니다.
        </p>
        <div className="flex flex-row items-center justify-end gap-8">
          <Close asChild>
            <Button
              type="button"
              variant="text"
              color="black"
              size="sm"
              className="h-48 w-94">
              취소
            </Button>
          </Close>
          <Button
            type="button"
            variant="outlined"
            color="frenchBlue"
            size="sm"
            onClick={() => notify.mutate()}>
            보내기
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

function UserInfo(props: { userId: string; children: ReactNode }) {
  const query = useQuery({
    queryKey: ['user-info', props.userId],
    queryFn: () => getUserInfo(props.userId),
  })

  return (
    <Dialog>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent className="flex !w-433 flex-col items-stretch !rounded-10 !border-none !p-0 !outline-none !ring-0">
        <div className="flex h-48 flex-row items-center border border-x-0 border-t-0 border-solid border-[#ccc]">
          <p className="inline-flex h-full w-133 flex-row items-center justify-center gap-4 border border-x-0 border-b-2 border-t-0 border-solid border-french-bleu text-center text-14 font-bold leading-22 text-french-bleu">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.6666 8.00065C14.6666 11.6825 11.6818 14.6673 7.99992 14.6673C4.31802 14.6673 1.33325 11.6825 1.33325 8.00065C1.33325 4.31875 4.31802 1.33398 7.99992 1.33398C11.6818 1.33398 14.6666 4.31875 14.6666 8.00065ZM7.99992 7.33398C8.36811 7.33398 8.66659 7.63246 8.66659 8.00065V11.3346C8.66659 11.7028 8.36811 12.0012 7.99992 12.0012C7.63173 12.0012 7.33325 11.7028 7.33325 11.3346V8.00065C7.33325 7.63246 7.63173 7.33398 7.99992 7.33398ZM7.99992 6.00065C8.36811 6.00065 8.66659 5.70217 8.66659 5.33398C8.66659 4.96579 8.36811 4.66732 7.99992 4.66732C7.63173 4.66732 7.33325 4.96579 7.33325 5.33398C7.33325 5.70217 7.63173 6.00065 7.99992 6.00065Z"
                fill="#3366B6"
              />
            </svg>
            회원 정보
          </p>
        </div>
        <div className="flex flex-col items-stretch gap-18 p-24">
          {query.data && (
            <div className="flex flex-row items-center gap-8">
              <Image
                alt={query.data.name}
                src={query.data.profileImageURL}
                width={40}
                height={40}
                className={'rounded-full object-cover'}
              />
              <p className="text-14 font-[500] leading-22">{query.data.name}</p>
            </div>
          )}
          {query.data && (
            <div className="flex flex-col gap-8 ">
              <div className="flex flex-row items-center">
                <p className="w-114 text-12 font-[500] leading-19">
                  유저 타입:
                </p>
                <p className="text-12 leading-19 text-[#667085]">
                  {query.data.type}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <p className="w-114 text-12 font-[500] leading-19">
                  이메일 아이디:
                </p>
                <p className="text-12 leading-19 text-[#667085]">
                  {query.data.id}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <p className="w-114 text-12 font-[500] leading-19">전화번호:</p>
                <p className="text-12 leading-19 text-[#667085]">
                  {query.data.phone}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <p className="w-114 text-12 font-[500] leading-19">
                  이너부스 회원코드:
                </p>
                <p className="text-12 leading-19 text-[#667085]">
                  {query.data.humanReadableID}
                </p>
              </div>
            </div>
          )}
          <Close asChild>
            <Button
              type="button"
              variant="filled"
              color="frenchBlue"
              size="sm"
              className="h-48 w-94 self-end">
              닫기
            </Button>
          </Close>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const checkSvg = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.49516 8.50483C7.75551 8.76518 8.17762 8.76518 8.43797 8.50483L11.1381 5.80473C11.3984 5.54438 11.3984 5.12227 11.1381 4.86192C10.8777 4.60157 10.4556 4.60157 10.1953 4.86192L7.49516 7.56202C7.23481 7.82237 7.23481 8.24448 7.49516 8.50483Z"
      fill="#23AC8A"
    />
    <path
      d="M5.08378 11.404C5.30881 11.3562 5.35848 11.0822 5.19582 10.9195L4.20341 9.92712C4.20068 9.92448 4.19796 9.92181 4.19527 9.91911L2.47141 8.19525C2.21106 7.9349 1.78895 7.9349 1.5286 8.19525C1.26825 8.4556 1.26825 8.87771 1.5286 9.13806L3.25246 10.8619C3.74969 11.3592 4.44361 11.5398 5.08378 11.404Z"
      fill="#23AC8A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4714 4.82859C14.7318 5.08894 14.7318 5.51105 14.4714 5.7714L9.38089 10.8619C8.59984 11.643 7.33351 11.643 6.55246 10.8619L4.8286 9.13806C4.56825 8.87771 4.56825 8.4556 4.8286 8.19525C5.08895 7.9349 5.51106 7.9349 5.77141 8.19525L7.49527 9.91911C7.75562 10.1795 8.17773 10.1795 8.43808 9.91911L13.5286 4.82859C13.7889 4.56824 14.2111 4.56824 14.4714 4.82859Z"
      fill="#23AC8A"
    />
  </svg>
)

export function ChatMessage({
  className,
  message,
  siblings,
  conversationId,
  myFirstMessageId,
  myMessagesCount,
  unreadCount,
}: {
  unreadCount: number
  message: Message
  siblings: {
    prev?: Message
    next?: Message
  }
  myFirstMessageId?: string
  myMessagesCount: number
  className?: string
  conversationId?: string
}) {
  const session = useSession()
  const currentUserType = session.data?.user.type
  const conversation = useConversation()
  const isAgency = conversation.data?.isAgency || false

  const position = useMemo(() => {
    const creatorTypes = ['creator', 'admin']
    if (currentUserType === 'admin') {
      return creatorTypes.includes(message.senderType || '') ? 'left' : 'right'
    }

    return message.senderType === currentUserType ? 'right' : 'left'
  }, [currentUserType, message.senderType])

  const timeOrUnreadComponent = (
    <p
      className={
        'c-b4 inline-flex flex-row items-center gap-8 self-end text-[#9e9e9e]'
      }>
      {position === 'right' && message.isRead === true && checkSvg}
      {unreadCount >= 3 ? (
        <NotifyUnread
          userId={message.userId}
          conversationId={conversationId || ''}
        />
      ) : (
        <span>{dayjs(message.createdAt).format('HH:mm')}</span>
      )}
    </p>
  )

  const imageComponent =
    currentUserType === 'admin' ? (
      <UserInfo userId={message.userId}>
        <Image
          fill
          alt="img"
          src={message.imageURL || getUserAvatarURL('')}
          className="object-cover"
        />
      </UserInfo>
    ) : (
      <Image
        fill
        alt="img"
        src={message.imageURL || getUserAvatarURL('')}
        className="object-cover"
      />
    )

  const isLastMessageOfGroup = message.userId !== siblings.prev?.userId
  const isFirstMessageOfGroup = message.userId !== siblings.next?.userId

  const shouldShowWarning =
    message.id === myFirstMessageId &&
    myMessagesCount < 5 &&
    currentUserType === 'user'

  // hide systemGenerated message with character card for creator
  if (message.isSystemGenerated && currentUserType === 'creator') {
    return null
  }

  // hide sent message request from user
  if (message.type === 'client-data' && currentUserType === 'user') {
    return null
  }

  return (
    <div
      className={cn('flex flex-col gap-8 w-full', {
        'items-start': position === 'left',
        'items-end': position === 'right',
      })}>
      <div
        className={cn(
          'flex flex-row items-start gap-8 self-stretch',
          {
            'justify-start': position === 'left',
            'justify-end': position === 'right',
            'mb-24': isLastMessageOfGroup,
          },
          className,
        )}>
        {position === 'left' && (
          <div className="relative size-48 shrink-0 overflow-hidden rounded-full">
            {isFirstMessageOfGroup && imageComponent}
          </div>
        )}
        {position === 'right' && isLastMessageOfGroup && timeOrUnreadComponent}
        <div
          className={cn('flex flex-col gap-8', {
            'items-start': position === 'left',
            'items-end': position === 'right',
          })}>
          <ChatMessageBubble
            className={`${position}-bubble`}
            variant={position}
            message={message}
          />
          {message.isSystemGenerated && (
            <ConversationCharacter conversationId={conversationId!} />
          )}
          {message.isSystemGenerated &&
            isAgency &&
            currentUserType === 'user' && (
              <div
                className={`flex flex-col items-stretch gap-8 rounded-r-20 rounded-bl-20 bg-[#0000000A] p-16 ${position}-bubble`}>
                <p className="c-b4 text-[#303030]">
                  간단한 폼을 작성해주시면, 전담 매니저가 확인 후 영업일 2~3일
                  내 안내드릴게요 😄
                </p>
                <Button type="button" asChild className="w-full">
                  <Link
                    href="https://inabooth.typeform.com/to/v8RiLmE2"
                    target="_blank">
                    작성하기
                  </Link>
                </Button>
              </div>
            )}
          {shouldShowWarning && <Warning />}
        </div>
        {currentUserType === 'admin' && position === 'right' && (
          <div className="relative size-48 shrink-0 overflow-hidden rounded-full">
            {isFirstMessageOfGroup && imageComponent}
          </div>
        )}
        {position === 'left' && isLastMessageOfGroup && timeOrUnreadComponent}
      </div>
    </div>
  )
}
