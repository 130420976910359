/* eslint-disable react/no-unused-prop-types */
import { Button } from '@/app/_primitives/Button'
import { DatePicker } from '@/app/_primitives/DatePicker'
import { InputLabel } from '@/app/_primitives/InputLabel'
import { TextArea } from '@/app/_primitives/TextArea'
import { TextField } from '@/app/_primitives/TextField'
import { ClientDataSchema } from '@/backend/models/Message'
import dayjs from 'dayjs'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { z } from 'zod'
import { SendMessageForm } from './SendMessageForm'

type ClientData = z.infer<typeof ClientDataSchema>

type FormProps = {
  filled?: boolean
  disableSubmit?: boolean
  onSubmit: () => void
  onBack?: () => void
  onClose?: () => void
}

function Initial(props: FormProps) {
  return (
    <div className="flex flex-col items-stretch">
      <div className="flex flex-row items-center justify-between gap-8 p-24">
        <div className="flex flex-col items-stretch justify-between">
          <p className="c-b4 text-[#8b8b8b]">답변 빨리 받는 방법!</p>
          <p className="c-b2">이너부스 문의 가이드</p>
        </div>
        <Button type={'button'} onClick={props.onSubmit}>
          확인하기
        </Button>
      </div>
      {!props.filled && (
        <div className="flex shrink-0 border-t border-tertiary px-24 py-12">
          <SendMessageForm disabled />
        </div>
      )}
    </div>
  )
}

function Step2(props: FormProps) {
  const form = useFormContext<ClientData>()

  return (
    <div className="flex flex-col items-stretch gap-24 p-24">
      <div className="flex flex-row items-center justify-between">
        <p className={'c-b2'}>아래의 정보를 입력해주세요.</p>
        <svg
          onClick={props.onClose}
          className="cursor-pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.7072 5.29289C6.31668 4.90237 5.68351 4.90237 5.29299 5.29289C4.90246 5.68342 4.90246 6.31658 5.29299 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.293 18.7071C17.6835 19.0976 18.3167 19.0976 18.7072 18.7071C19.0977 18.3166 19.0977 17.6834 18.7072 17.2929L13.4143 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5857L6.7072 5.29289Z"
            fill="black"
          />
        </svg>
      </div>
      <InputLabel label={'기업명'} variant="dark">
        <TextField
          disableCheckValidity
          {...form.register('name')}
          placeholder="(주)이너부스"
        />
      </InputLabel>
      <InputLabel label={'개발하고 싶은 상품'} variant="dark">
        <TextField
          disableCheckValidity
          {...form.register('product')}
          placeholder="2030 여성 소비자를 위한 화장품 패키지"
        />
      </InputLabel>
      <Controller
        name="date"
        control={form.control}
        render={({ field }) => {
          return (
            <InputLabel label={'예상 출시 시기'} variant="dark">
              <DatePicker
                value={field.value}
                onChange={(date) => {
                  field.onChange(date)
                }}
                displayMode="popover"
                mode="date">
                <TextField
                  className="text-start"
                  readOnly
                  value={dayjs(field.value).format('YYYY년 MM월 DD')}
                />
              </DatePicker>
            </InputLabel>
          )
        }}
      />
      <InputLabel label={'크리에이터에게 궁금한 정보'} variant="dark">
        <TextArea {...form.register('information')} placeholder="입력..." />
      </InputLabel>
      <div className="flex flex-row items-center justify-end">
        <button
          type="button"
          disabled={props.disableSubmit}
          onClick={props.onSubmit}
          className="grid size-48 place-content-center rounded-full bg-french-bleu focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-tertiary">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="white"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.86193 11.8619C8.60158 12.1223 8.60158 12.5444 8.86193 12.8047C9.12228 13.0651 9.54439 13.0651 9.80474 12.8047L13.6667 8.94281C14.1874 8.42211 14.1874 7.57789 13.6667 7.05719L9.80474 3.19526C9.54439 2.93491 9.12228 2.93491 8.86193 3.19526C8.60158 3.45561 8.60158 3.87772 8.86193 4.13807L12.0572 7.33333H2.66667C2.29848 7.33333 2 7.63181 2 8C2 8.36819 2.29848 8.66667 2.66667 8.66667H12.0572L8.86193 11.8619Z"
              fill="currentFill"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

function Step3(props: FormProps) {
  const form = useFormContext<ClientData>()

  return (
    <div className="flex flex-col items-stretch gap-24 p-24">
      <div className="flex flex-row items-center justify-between">
        <p className={'c-b2'}>아래의 정보를 입력해주세요.</p>
        <svg
          onClick={props.onClose}
          className="cursor-pointer"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.7072 5.29289C6.31668 4.90237 5.68351 4.90237 5.29299 5.29289C4.90246 5.68342 4.90246 6.31658 5.29299 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.293 18.7071C17.6835 19.0976 18.3167 19.0976 18.7072 18.7071C19.0977 18.3166 19.0977 17.6834 18.7072 17.2929L13.4143 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5857L6.7072 5.29289Z"
            fill="black"
          />
        </svg>
      </div>
      <p className="c-b4 -mt-12 text-[#303030]">
        오프라인 상태가 되시면 문자(카카오톡) 및 이메일로
        <br />
        답변 알림을 보내드려요.
      </p>
      <TextField
        disableCheckValidity
        placeholder="전화번호 입력..."
        {...form.register('contact')}
      />
      <div className="flex flex-row items-center justify-between">
        <button
          type="button"
          onClick={props.onBack}
          className="grid size-48 place-content-center rounded-full border border-french-bleu bg-white focus-visible:outline-none disabled:cursor-not-allowed">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.13813 11.8619C7.39848 12.1223 7.39848 12.5444 7.13813 12.8047C6.87778 13.0651 6.45567 13.0651 6.19532 12.8047L2.3334 8.94281C1.8127 8.42211 1.8127 7.57789 2.3334 7.05719L6.19532 3.19526C6.45567 2.93491 6.87778 2.93491 7.13813 3.19526C7.39848 3.45561 7.39848 3.87772 7.13813 4.13807L3.94287 7.33333H13.3334C13.7016 7.33333 14.0001 7.63181 14.0001 8C14.0001 8.36819 13.7016 8.66667 13.3334 8.66667H3.94287L7.13813 11.8619Z"
              fill="#3366B6"
            />
          </svg>
        </button>
        <Button
          type={'button'}
          disabled={props.disableSubmit || props.filled}
          onClick={props.onSubmit}>
          완료하기
        </Button>
      </div>
    </div>
  )
}

export const clientDataForms: Record<number, React.FC<FormProps>> = {
  0: Initial,
  1: Step2,
  2: Step3,
} as const
